@tailwind base;
@tailwind components;
@tailwind utilities;


.main_loader_style{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_loader_style span{
    font-family: 'inter_semibold';
    color: var(--dark);
}

@import url('./color.css');
@import url('./fontFamily.css');


/* font-families classes of poppins */

.poppins_regular {
  font-family: 'poppins_regular';
}

.poppins_bold {
  font-family: "poppins_bold";
}

.poppins_medium {
  font-family: "poppins_medium";
}

.poppins_semibold {
  font-family: "poppins_semibold";
}

.poppins_light {
  font-family: "poppins_light";
}

/* font-families classes of roboto */

.roboto_regular {
  font-family: 'roboto_regular';
}

.roboto_bold {
  font-family: "roboto_bold";
}

.roboto_medium {
  font-family: "roboto_medium";
}

.roboto_light {
  font-family: "roboto_light";
}


/* font-families classes of manrope */

.manrope_regular {
  font-family: 'manrope_regular';
}

.manrope_bold {
  font-family: "manrope_bold";
}

.manrope_medium {
  font-family: "manrope_medium";
}

.manrope_semibold {
  font-family: "manrope_semibold";
}

.manrope_light {
  font-family: "manrope_light";
}



/* font-families classes of plusJakara */

.plusJakara_regular {
  font-family: 'plusJakara_regular';
}

.plusJakara_bold {
  font-family: "plusJakara_bold";
}

.plusJakara_medium {
  font-family: "plusJakara_medium";
}

.plusJakara_semibold {
  font-family: "plusJakara_semibold";
}

.plusJakara_light {
  font-family: "plusJakara_light";
}



/* font-families classes of inter */

.inter_regular {
  font-family: 'inter_regular';
}

.inter_bold {
  font-family: "inter_bold";
}

.inter_medium {
  font-family: "inter_medium";
}

.inter_semibold {
  font-family: "inter_semibold";
}

.inter_light {
  font-family: "inter_light";
}

/* text colors classes */
.text_primary {
  color: var(--primary);
}

.text_secondarydark {
  color: var(--secondarydark);
}

.text_secondary {
  color: var(--secondary);
}

.text_white {
  color: var(--white);
}

.text_light {
  color: var(--light);
}

.text_darkprimary {
  color: var(--darkprimary);
}

.text_black {
  color: var(--black);
}

.text_dark {
  color: var(--dark)
}

/* background colors classes */
.bg_primary {
  background-color: var(--primary);
}

.bg_darkprimary {
  background-color: var(--darkprimary);
}

.bg_secondarydark {
  background-color: var(--secondarydark);
}

.bg_secondary {
  background-color: var(--secondary);
}

.bg_white {
  background-color: var(--white);
}

.bg_light {
  background-color: var(--light);
}

.bg_black {
  background-color: var(--black);
}

.bg_dark {
  background-color: var(--dark)
}

.screen_size_responsive {
  width: 100%;
}

@media (min-width: 1024px) {
  .screen_size_responsive {
    max-width: 900px;
    margin: auto;
  }
}


.coupon_card {
  position: relative;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px,
    rgba(33, 35, 38, 0.1) 0px -10px 10px -10px;
  /* Add shadow for both top and bottom */
  overflow: hidden;
}

.circle1,
.circle2 {
  position: absolute;
  background-color: #f0f7f7;
  width: 30px;
  height: 30px;
  z-index: 9999999px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.circle1 {
  left: -20px;
}

.circle2 {
  right: -20px;
}


.input-container {
  display: flex;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: .7rem 1rem;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 0;
}

.bg-overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #46464662;
  box-shadow: 0px 2px 4px 0px #00000014;
  z-index: 1;
}

.input-container input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.input-container input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input-container input:not(:last-child) {
  border-right: none;
}

.input-container .divider {
  background: #ccc;
  height: 40px;
  width: 2px;
}


.reuse_btn {
  border-radius: 25px;
  padding: .3rem 1.5rem;
  width: 150px;
  background-color: #01AD8E;
  border: none;
  color: white;
  /* width: 100%  */
}

.header_file_style {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  margin: auto;
  width: 100%;
}

@media (min-width: 1024px) {
  .header_file_style {
    max-width: 900px;
    margin: auto;
  }
}

.container_logo {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.container_logo2 {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 120px;
  margin: auto;
}

.business-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
  overflow: hidden;
}

.business-cover2 {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 12px;
  overflow: hidden;
}

.logo2 {
  position: absolute;
  left: 10px;
  width: 40px;
  transform: translateY(-50%);
  height: 40px;
  top: 50%;
  border: 2px solid white;
  background-color: white;
  border-radius: 50%;
  object-fit: cover;
  z-index: 3;
}

.logo {
  position: absolute;
  left: 30px;
  width: 90px;
  transform: translateY(-50%);
  height: 90px;
  top: 50%;
  border: 2px solid white;
  background-color: white;
  border-radius: 50%;
  object-fit: cover;
  z-index: 3;
}

.logo-gradient {
  position: absolute;
  bottom: 0;
  /* left: 20px; */
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(51, 153, 153, 0) 0%, #339999 71.3%);
  z-index: 2;
}

.logo-gradient2 {
  position: absolute;
  bottom: 0;
  /* left: 20px; */
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(51, 153, 153, 0) 0%, #339999 71.3%);
  z-index: 2;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  /* Overlay with some transparency */
  border-radius: 20px;
  z-index: 1;
}

.overlay2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  /* Overlay with some transparency */
  border-radius: 12px;
  z-index: 1;
}
/* 
.form_bootstrap .form-control {
  color: var(--secondarydark) !important;
} */

.custom_control::placeholder {
  color: var(--secondarydark) !important; /* Replace with your secondary dark color */
}

.form_bootstrap .css-13cymwt-control {
  border-radius: 12px !important;
  border-width: 0px !important;
  border-color: transparent !important;
}

.form_bootstrap .css-b62m3t-container {
  border-radius: 12px !important;
}

.form_bootstrap .css-t3ipsp-control {
  border-radius: 12px !important;
  border-color: transparent !important;
  border-width: 0px !important;
}

.form_bootstrap .css-t3ipsp-control {
  box-shadow: none !important;
  border-color: transparent !important;
}

.form_bootstrap .css-1fdsijx-ValueContainer {
  padding: 8px 16px !important;
}

.timezone_select .css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.timezone_select .css-1dimb5e-singleValue {
  color: var(--primary) !important;
  font-family: 'inter_regular' !important;
  font-size: 14px !important;
}

.timezone_select .css-1u9des2-indicatorSeparator {
  display: none !important;
}
/* 
.form_reactstrap .form-control {
  color: var(--secondarydark) !important;
} */

.form_reactstrap .form-control:focus {
  box-shadow: none !important;
}



/* verify code css */


.login_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

/* .f_head {
  color: var(--head-color);
  font-family: "inter_bold";
  font-size: 20px;
}*/

.resend_buton {
  font-family: "inter_regular";
  color: #6c7774;
  font-size: 14px;
  text-align: center;
}

.main_div_login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inp_width {
  width: 54px !important;
  height: 54px !important;
}

@media (max-width:350px) {
  .inp_width {
    width: 45px !important;
    height: 45px !important;
  }
}

@media (max-width:310px) {
  .inp_width {
    width: 40px !important;
    height: 40px !important;
  }
}

.error_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'inter_regular';
  color: #ed6054;
}

.error_div img {
  width: 18px;
  height: 18px;
}

.custom_control:focus {
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.counter_con {
  font-family: 'inter_medium';
  font-size: 15px;
  color: #02150f;
}

/* custom button */

.custome_button_design {
  width: 100%;
  max-width: 400px;
  padding: 12px 0;
  background-color: var(--darkprimary);
  border-radius: 34px;
  font-family: 'inter_medium';
  color: var(--white);
}

/* tebs forgot password */
.nav-tabs-main {
  width: 100%;
  background: linear-gradient(0deg, #c8ded3, #c8ded3),
    linear-gradient(0deg, #e6f0eb, #e6f0eb);
  border-radius: 9999px !important;
  padding: 4px 4px;
  display: flex;
  justify-content: space-around;
}

.sign_tab .nav-link {
  font-family: "inter-medium";
  color: #6c7774 !important;
}

.sign_tab .nav-link.active {
  color: #02150f !important;
}

.nav-item-main {
  width: 32.5%;
}

.nav-item-main .active {
  background: rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 1px 8px 0px rgba(20, 20, 20, 0.08);
  height: 40px !important;
  border-radius: 9999px !important;
  width: 100%;
}

.nav-item-main .nav-link {
  border: none !important;
  border-radius: 999px !important;
  height: 100%;
}

.sign_tab .nav-link {
  font-family: "inter_bold";
  font-size: 17px;
  color: var(--secondary) !important;
}

.sign_tab .nav-link.active {
  color: #02150f !important;
}

.nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #252525 !important;
}

.nav-link:hover {
  border: 0 !important;
}

.bg-overlay {
  background-color: #00000080;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.nav-link:focus-visible:hover {
  outline: 0;
  box-shadow: none !important;
}

.nav-item-main .nav-link {
  border: none !important;
  border-radius: 999px !important;
  height: 100%;
}

.skelet {
  height: 14rem !important;
  width: 15rem;
}

.phon_inp input[type="tel"] {
  border-radius: 12px;
  border: 1px solid var(--grey-200, #e9e6e9);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  height: 45px !important;
  font-family: 'inter_medium' !important;
  width: 100%;
}

.phon_inp input[type="tel"] {
  font-size: 16px;
  max-width: 100%;
  -webkit-appearance: none;
}

.phon_inp input[type="tel"]:focus {
  font-size: 16px;
  max-width: 100%;
  -webkit-appearance: none;
  user-scalable: no;
  touch-action: manipulation;
}


.shadow_def .form-control {
  border-radius: 12px;
  border: 1px solid var(--grey-200, #e9e6e9);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  height: 50px !important;
  font-family: 'inter_medium' !important;
  width: 100%;

}

.phon_inp .flag-dropdown {
  border-radius: 12px 0px 0px 12px !important;
  border-right: transparent !important;
  background-color: white !important;
  border: 1px solid var(--grey-200, #e9e6e9) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.phon_inp .flag-dropdown .selected-flag:hover {
  background-color: transparent !important;
}

/* .phon_inp .form-control:focus {
  box-shadow: 0px 0px 0px 3px #e6ffff !important;
} */
/* 
.shadow_def input[type="email"]:focus {
  border-radius: 12px;
  border: 1px solid #d1ffff !important;
  background: #fff;
  box-shadow: 0px 0 px 0px 3px #bfe9c5 !important;
}

.shadow_def input[type="text"]:focus {
  border-radius: 12px;
  border: 1px solid var(--primary) !important;
  background: #fff;
  box-shadow: 0px 0px 0px 3px #bfe9c5 !important;
} */
.line_join_now {
  display: block;
}

@media (max-width:340px) {
  .line_join_now {
    display: none;
  }
}


@media (min-width: 1024px) {
  .bottom_button_fixed {
    max-width: 900px;
    margin: auto;
  }
}

.bottom_button_fixed {
  position: fixed;
  background-color: var(--light);
  bottom: 0;
  right: auto;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
}

.purchase_screen {
  background-image: url('../assets/png/purchase_success.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

:where(.vi__character) {
  border: 1px solid #EAEBF0 !important;
  border-radius: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: 0px 1px 2px 0px #1018280A !important;
  font-size: 20px !important;
  font-family: 'inter_semibold' !important;
}

.vi__character--selected {
  outline: none !important;
  color: black !important;
  position: relative;
}

.vi__character--selected::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: auto;
  height: 50%;
  width: 1px;
  background-color: var(--darkprimary);
  animation: blink 1.5s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@media (min-width: 430px) {
  :where(.vi__character) {
    height: 60px !important;
  }
}

:where(.vi__character--inactive) {
  background-color: white !important;
  color: black !important;
}

.bottom_navigate .bottom-nav {
  position: fixed;
  max-width: 400px;
  width: 100%;
  right: auto;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  margin: auto;
  box-shadow: 0px 0px 24px 0px #00000026;
  border-radius: 100px;
}

.market_navigate {
  position: fixed;
  max-width: 400px;
  width: 100%;
  right: auto;
  left: 50%;
  bottom: 4.5rem;
  transform: translateX(-50%);
  margin: auto;
}

.input_custom_simple {
  background-color: #F5F4F7;
  font-family: 'inter_regular';
  border-radius: 100px;
  padding: 6px 10px 6px 38px;
}

.input_custom_simple:focus {
  outline: none !important;
}

.market_deals_box {
  position: fixed;
  max-width: 400px;
  width: 100%;
  right: auto;
  left: 50%;
  bottom: 9rem;
  transform: translateX(-50%);
  margin: auto;
}

.left-side {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* header styling */

.nav_item {
  position: fixed;
  bottom: 20px;
  border-radius: 100px;
  max-width: 400px;
  box-shadow: 0px 0px 24px 0px #00000026;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  padding: .5rem 1rem;
  background-color: #ffffff;
  z-index: 999;
}

.nav_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_item a {
  width: auto !important;
  padding: 8px !important;
}


.shairDiv00 .MuiMenu-paper ul {
  display: flex !important;
  flex-wrap: wrap !important;
  max-width: 250px;
  justify-content: center !important;
}

.shairDiv00 .MuiMenu-paper ul li {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.shairDiv00 .MuiMenu-paper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 4px !important;
}

/* sheet styling */


@media (min-width: 1024px) {
  .sheet_container .react-modal-sheet-container {
    max-width: 860px !important;
    margin: auto !important;
    box-shadow: none !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.sheet_container .react-modal-sheet-container {
  border-radius: 0px !important;
  width: 100%;
  height: auto !important;
}

.bottom_comment {
  width: 100%;
  position: fixed;
  z-index: 99999;
  bottom: 0;
  height: auto;
  background-color: var(--white);
}

@media (min-width: 1024px) {
  .bottom_comment {
    max-width: 900px;
    margin: auto;
  }
}

.bottom_comment .form-control {
  padding-top: 10px;
  padding-bottom: 10px;
  border: none !important;
}

.bottom_comment .form-control:focus {
  box-shadow: none !important;
}

/* progress bar */

.progress_main {
  position: relative !important;
}

.progress_main .progress {
  border-radius: 20px !important;
}

.progress_main .progress-bar {
  border-right: groove !important;
  font-family: 'plusJakara_medium' !important;
}

.progress_main .progress_text {
  position: absolute;
  bottom: 10px;
  top: 18px;
  color: var(--secondary);
}


/* accordian styling */

.accordian .accordion-button {
  background-color: white;
  box-shadow: 0px 1px 2px 0px #E4E5E73D;
  color: black;
  height: 50px !important;
  border-radius: 16px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 16px !important;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 16px !important;
}

.accordian .accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}

.accordian .accordion-body {
  padding: 0px !important;
}

.accordian .accordion-item {
  display: flex !important;
  gap: 1rem !important;
  border-radius: 16px !important;
  flex-direction: column !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.accordian .accordion-collapse {
  border-radius: 16px !important;
  background-color: transparent !important;
}

.accordian .accordion-button::after {
  content: '';
  background-image: url('../assets/svg/arrowdown.svg');
  background-size: auto;
  height: 2rem;
  position: absolute;
  top: 20px;
  right: 0;
  transition: none;
}

.accordian .accordion-button:not(.collapsed)::after {
  content: '';
  transform: none;
  background-image: url('../assets/svg/arrowup.svg');
  background-size: auto;
}

.accordian .accordion-button:focus {
  box-shadow: none;
  border-bottom: transparent !important;
}

/* accordian faq */

.accordian_faq .accordion-button:not(.collapsed) {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
}

.accordian_faq .accordion-body {
  padding: .5rem 1rem 2rem 2rem !important;
}

.accordian_faq .accordion-item {
  border-radius: 16px !important;
}

.accordian_faq .accordion-header {
  border-radius: 16px !important;
}

.accordian_faq .accordion {
  border-radius: 16px !important;
}

.accordian_faq .accordion-button:focus {
  box-shadow: none;
  border-bottom: #ACB5BB;
}

.accordian_faq .accordion-button::after {
  content: '' !important;
  background-image: url('../assets/svg/pluslight.svg');
  background-size: auto;
  height: 2rem;
  position: absolute;
  top: 20px;
  right: 0;
  transition: none;
}

.accordian_faq .accordion-button:not(.collapsed)::after {
  content: '' !important;
  background-image: url('../assets/svg/minuslight.svg');
  transform: none;
  background-size: auto;
}

/* accordian revoke */


/* accordian faq */

.accordian_revoke .accordion-button:not(.collapsed) {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
}

.accordian_revoke .accordion-body {
  padding: 12px !important;
}

.accordian_revoke .accordion-item {
  border-radius: 16px !important;
}

.accordian_revoke .accordion-header {
  border-radius: 16px !important;
}

.accordian_revoke .accordion {
  border-radius: 16px !important;
}

.accordian_revoke .accordion-button:focus {
  box-shadow: none;
  border-bottom: #ACB5BB;
}

.accordian_revoke .accordion-button::after {
  content: '' !important;
  background-image: url('../assets/svg/arrowdown.svg');
  background-size: auto;
  height: 2rem;
  position: absolute;
  top: 20px;
  right: 0;
  transition: none;
}

.accordian_revoke .accordion-button:not(.collapsed)::after {
  content: '' !important;
  background-size: auto;
  transform: none;
  background-image: url('../assets/svg/arrowup.svg');
}


.story_height::-webkit-scrollbar,
.scrolbar::-webkit-scrollbar {
  width: 0.6vh;
}

.story_height::-webkit-scrollbar-thumb,
.scrolbar::-webkit-scrollbar-thumb {
  background: var(--light-secondary);
  border-radius: 1vh;
}

.story_height::-webkit-scrollbar-track,
.scrolbar::-webkit-scrollbar-track {
  background-color: rgb(227, 223, 223);
  border-radius: 1vh;
}

/* time picker  */

.react-time-picker {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 2px 0px #0000001F !important;
  border: 1px solid #0000000A !important;
  height: 40px !important;
  width: 6.5rem !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.react-time-picker__inputGroup__input {
  font-family: 'inter_regular' !important;
  outline: none !important;
}

.react-time-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}

.react-time-picker__inputGroup__input:invalid:focus {
  border: none !important;
  outline: none !important;

}

.react-time-picker__button:enabled {
  display: none !important;
}

.react-time-picker__clock {
  display: none !important;

}

/* file upload css */

.id_image .paperClip {
  height: 2rem;
  width: 4rem;
}

.user_image .plus {
  position: absolute;
  top: -2px;
  right: -6px;
  width: 1.7rem;
  height: 1.7rem;
  background-color: black;
  border: 2px solid white;
  border-radius: 50%;
}


.identity_image {
  border-radius: 1rem;
  border: 1px solid rgb(218, 217, 217);
  height: 10rem;
  width: 100%;
}

/* .bus_img {
  width: 4.9rem;
  height: 4.9rem;
} */

.bus_grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(4.9rem, 4.9rem));
  justify-content: center;
  align-items: center;
  grid-auto-rows: auto;
}

.identity_image {
  width: 100%;
  max-height: 100px;
  background-color: white;
  position: relative;
}

.remove_button {
  position: absolute;
  top: 0;
  z-index: 9999;
  right: 0;
}

.remove_button button {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.id_image {
  background-color: black;
  color: white;
  border-radius: 50%;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  position: relative;
}